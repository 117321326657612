body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #fff;
  padding: 0px;
}

/* https://stackoverflow.com/questions/69261011/disable-pull-to-refresh-in-ios-15-safari */
body {
  overflow: hidden;
}

#container {
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

a {
  color: #fff;
}

.product {
  padding: 15px;
  max-width: 150px;
  min-width: 120px;
  /* border-bottom: 1px solid #666; */
}
.product img {
  border-radius: 20px;
}
.product a {
  display: flex;
  text-align: center;
  /* font-size: 20px; */
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 132px; */
}
.product h2 {
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  margin: 0;
  height: 32px;
  margin-top: 5px;
}
.status {
  position: fixed;
  bottom: 5px;
  background: rgba(256, 256, 256, 0.2);
  right: 5px;
  padding: 3px 8px;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.5px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  z-index: 1000;
}

input[type="search"] {
  outline: none;
  width: 100%;
  padding: 0px 20px;
  border: 2px solid rgba(256, 256, 256, 0.5);
  border-radius: 50px;
  box-sizing: border-box;
  /* margin: auto; */
  /* max-width: 250px; */
  border-color: transparent;
  /* border: none; */

  background-color: rgba(256, 256, 256, 0.2);
  color: #fff;
  font-size: 20px;
  line-height: 50px;
  transition: 0.3s;
  /* transition: 0; */
  letter-spacing: -0.5px;
}
input[type="search"]:focus {
  /* max-width: 300px; */
  transition: 0.5s;
  border: 2px solid rgba(256, 256, 256, 1);
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
  * Correct the inability to style clickable types in iOS and Safari.
  */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/* .splide__slide.is-active .tvactions {
  display: block;
} */
/* .splide__slide .tvactions {
  display: none;
} */
:root {
  /* --primary-color: #00d9e0; */
  --primary-color: #fff;
  --text-color: #000;
}
.tvactions button {
  background-color: var(--primary-color);
  border-radius: 20px;
  outline: none;
  border: 0;
  color: var(--text-color);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  column-gap: 10px;
  padding: 0px 15px;
  margin: auto;
  font-size: 11px;
  line-height: 32px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-top: 20px;
  cursor: pointer;
  /* -webkit-appearance: none; */
}

.search {
  padding: 15px 45px;
}
.search--focused {
  padding: 0 30px;
  position: absolute;
  /* background-color: #2d2d2d; */
  background-color: #000;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  box-sizing: border-box;
  /* height: 100vh;
  width: 100vw; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  /* transition: 300ms; */
}
.search--focused svg {
  width: 32px;
  height: 32px;
  fill: var(--text-color);
}

.search--focused input {
  background-color: transparent;
  border-color: transparent !important;
  font-size: 26px;
  padding: 0;
}
